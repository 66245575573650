import React, { ReactElement } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";

type CustomSelectProps = {
  label?: string;
  onChange: (v: any) => void;
  value: any;
  icon?: ReactElement<any, any>;
  data?: any[];
  itemLabel?: string;
  itemValue?: string;
  multiple?: boolean;
  forceSelected?: boolean;
  allowNull?: boolean;
  disabled?: boolean;
};

export default ({
  label,
  onChange,
  value,
  icon,
  data,
  itemLabel,
  itemValue,
  multiple,
  forceSelected,
  allowNull,
  disabled,
}: CustomSelectProps) => {
  return (
    <FormControl
      fullWidth
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        padding: ".5rem 0",
      }}
    >
      {icon}
      <Select
        displayEmpty
        value={value}
        size="small"
        onChange={onChange}
        color="secondary"
        style={{ marginLeft: ".5rem", width: "100%" }}
        disabled={!data || (data.length <= 1 && forceSelected) || disabled}
        multiple={multiple}
      >
        <MenuItem disabled value="">
          <Typography color="secondary" style={{ fontSize: 13 }}>
            <em>{label}</em>
          </Typography>
        </MenuItem>
        {allowNull && (
          <MenuItem value={null}>
            <Typography color="secondary" style={{ fontSize: 13 }}>
              Aucun
            </Typography>
          </MenuItem>
        )}
        {data?.map((row: any) => (
          <MenuItem key={row[itemValue]} value={row[itemValue]}>
            <Typography style={{ fontSize: 13 }}>{row[itemLabel]}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
