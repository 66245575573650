import React, { useEffect, useState, useCallback } from "react";
import Api from "../api/pluginApi";
import { Autocomplete, CircularProgress, TextField, Typography } from "@mui/material";

type SelectUserInputProps = {
  onChange: (v: any) => void;
  disabled: boolean;
};

export default ({ onChange, disabled }: SelectUserInputProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  const fetchUser = async (term) => {
    try {
      setLoading(true);
      const data = await Api.searchUsers(term);
      setOptions(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleInputValue = useCallback(async () => {
    if (inputValue && inputValue.length >= 2) {
      await fetchUser(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    void handleInputValue();
  }, [handleInputValue]);

  return (
    <Autocomplete
      value={value}
      size="small"
      color="secondary"
      open={open}
      disabled={disabled}
      onChange={(event, newValue) => {
        // setValue(newValue ? newValue.email : null);
        setValue("");
        onChange(newValue);
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option?.email ?? "" === value}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.email)}
      options={options}
      loading={loading}
      loadingText="Chargement..."
      noOptionsText="Aucun utilisateur trouvé"
      style={{ width: "100%", margin: "1rem 0 .5rem 0" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <Typography color="secondary" style={{ fontSize: 13 }}>
              Recherche...
            </Typography>
          }
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
