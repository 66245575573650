import axios from "./axios";

const getRequestHeader = () => {
  return { Authorization: `Bearer ${localStorage.getItem("token")}` }; //replace by saved token name in localstorage after connection
};

//AUTH
export const connect = async (body: { identity: string; credential: string }) => {
  //email + sso token
  const { data } = await axios.post("api/1/outlook/auth", body);
  return data;
};

export const authVerify: () => Promise<boolean> = async () => {
  try {
    await axios.get("api/1/outlook/auth", { headers: getRequestHeader() });
    return true;
  } catch (e) {
    return false;
  }
};

export const ssoEmailValidation = async (email: string) => {
  const { data } = await axios.get(`api/1/outlook/auth/${email}`);
  return data;
};

//PERMISSIONS
export const getPermissions: () => Promise<PermissionsApi> = async () => {
  const { data } = await axios.get("api/1/outlook/member", { headers: getRequestHeader() });
  return data;
};

//MEMBER
export const searchUsers = async (criteria: string) => {
  const { data } = await axios.post("api/1/outlook/member", { criteria }, { headers: getRequestHeader() });
  return data;
};

// Member Settings
export const getUsers = async (userUid: string): Promise<PermissionsApi> => {
  const { data } = await axios.get(`api/1/outlook/member/${userUid}`, { headers: getRequestHeader() });
  return data;
};

//LOCATION
export const getLocations = async () => {
  const { data } = await axios.get("api/1/outlook/location", { headers: getRequestHeader() });
  return data;
};

//SPACES
export const getSpaces = async (uid: string) => {
  const { data } = await axios.get(`api/1/outlook/location/${uid}/space`, { headers: getRequestHeader() });
  return data;
};

//TEMPLATE
export const createTemplate = async (body: Template) => {
  const { data } = await axios.put(`api/1/outlook/template`, body, { headers: getRequestHeader() });
  return data;
};

export const updateTemplate = async (body: Template) => {
  const { data } = await axios.patch(`api/1/outlook/template/${body.msgid}`, body, { headers: getRequestHeader() });
  return data;
};

export const getTemplate = async (msgid: string): Promise<Template> => {
  const { data } = await axios.get(`api/1/outlook/template/${msgid}`, { headers: getRequestHeader() });
  return data;
};

export type PermissionsParamsApi = {
  notify: boolean;
  media1: 0 | 1 | 2 | 3;
  media2: 0 | 1 | 2 | 3;
  media3: 0 | 1 | 2 | 3;
};
export type PermissionsApi = {
  allow_media_email: boolean;
  allow_media_sms: boolean;
  allow_media_teams: boolean;
  allow_customize: boolean;
  allow_delegate: boolean;
  allow_default_space: boolean;
  params: PermissionsParamsApi;
};

export type Options = {
  token: boolean;
  notify: boolean;
  access: boolean;
};
export type Attendee = {
  identity: string; //email
  options: Options;
};
export type Template = {
  settingsid: string;
  msgid: string;
  attendees: Attendee[];
  location: { uid: string };
  address: { uid: string };
  space: { uid: string };
  organizer_params: {
    notify: boolean;
    media1: 0 | 1 | 2 | 3;
    media2: 0 | 1 | 2 | 3;
    media3: 0 | 1 | 2 | 3;
  };
  organized_for?: {
    uid: string;
    label: string;
  };
  organized_for_params?: {
    notify: boolean;
    media1: 0 | 1 | 2 | 3;
    media2: 0 | 1 | 2 | 3;
    media3: 0 | 1 | 2 | 3;
  };
};

export default {
  connect,
  authVerify,
  ssoEmailValidation,
  searchUsers,
  getUsers,
  getLocations,
  getSpaces,
  getPermissions,
  createTemplate,
  updateTemplate,
  getTemplate,
};
