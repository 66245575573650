import axios from "axios";

export default axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.API_URL,
  timeout: 8000,
  headers: {
    Accept: "application/json",
  },
});
