import React from "react";
import { Modal, Typography, FormGroup, FormControlLabel, Checkbox, CardContent, Card, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type AttendeesModalProps = {
  open: boolean;
  onClose: () => void;
  attendee: any;
  onEditAttendee: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const AttendeeModal = ({ open, onClose, attendee, onEditAttendee }: AttendeesModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Card
        style={{
          backgroundColor: "white",
          maxHeight: "60%",
          width: "90%",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          outline: "none",
        }}
      >
        <IconButton
          onClick={onClose}
          style={{
            position: "absolute",
            right: ".5rem",
            top: ".5rem",
          }}
        >
          <CloseIcon />
        </IconButton>
        <CardContent style={{ padding: "2rem 2rem 1rem 2rem" }}>
          <Typography color="secondary" style={{ fontSize: 15, fontWeight: "600" }}>
            {attendee.email}
          </Typography>
          <FormGroup style={{ padding: ".5rem", marginTop: ".5rem" }}>
            <FormControlLabel
              control={<Checkbox checked={attendee.flexAccess.access.sendQr} onChange={onEditAttendee} name="sendQr" />}
              label={
                <Typography color="secondary" style={{ fontSize: 13 }}>
                  Envoyer un QR-Code d’invitation
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={attendee.flexAccess.access.sendNotificationOnChecking}
                  onChange={onEditAttendee}
                  name="sendNotificationOnChecking"
                  disabled={!attendee.flexAccess.access.sendQr}
                />
              }
              label={
                <Typography color="secondary" style={{ fontSize: 13 }}>
                  Envoyer une notification à son arrivée
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={attendee.flexAccess.access.allowSelfAccess}
                  onChange={onEditAttendee}
                  name="allowSelfAccess"
                  disabled={!attendee.flexAccess.access.sendQr}
                />
              }
              label={
                <Typography color="secondary" style={{ fontSize: 13 }}>
                  Autoriser un accès autonome
                </Typography>
              }
            />
          </FormGroup>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AttendeeModal;
