import React, { useEffect, useState, useMemo } from "react";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import emailRegex from "email-regex";
import AttendeeModal from "./AttendeeModal";
import OrganizerModal from "./OrganizerModal";
import CustomSelect from "./CustomSelect";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import Api, { PermissionsApi, Template } from "../api/pluginApi";
import Fuse from "fuse.js";
import SelectUserInput from "./SelectUserInput";
import theme from "./theme";
import AppointmentCompose = Office.AppointmentCompose;
import { User } from "../api/types";
import ConfirmationModal from "./ConfirmationModal";
import jwtDecode from "jwt-decode";
import SuccessModal from "./SuccessModal";

// eslint-disable-next-line no-redeclare
/* global Office */

export const App = () => {
  const [currentUser, setCurrentUser] = useState<PermissionsApi>(null);
  const [sites, setSites] = useState<any[]>([]);
  const [entrances, setEntrances] = useState<any[]>([]);
  const [spaces, setSpaces] = useState<any[]>([]);
  const [organizers, setOrganizers] = useState<User[]>([]);
  const [attendees, setAttendees] = useState<User[]>([]);
  const [selectedSite, setSelectedSite] = useState<any>("");
  const [selectedEntrance, setSelectedEntrance] = useState<any>("");
  const [selectedSpace, setSelectedSpace] = useState<any>("");
  const [selectedAttendee, setSelectedAttendee] = useState<any>();
  const [selectedOrganizer, setSelectedOrganizer] = useState<any>();
  const [attendeeModalState, setAttendeeModalState] = useState(false);
  const [organizerModalState, setOrganizerModalState] = useState(false);
  const [confirmationModalState, setConfirmationModalState] = useState(false);
  const [isAlreadyCreated, setIsAlreadyCreated] = useState(false);
  const [successModalState, setSuccessModalState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [onSearchUsers, setOnSearchUsers] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [retrieveEvent, setRetrieveEvent] = useState<Template | null>(null);

  const filteredAttendees = useMemo(() => {
    const attendeesWithoutSite = attendees.filter(
      (attendee) => attendee.email !== sites.find((k) => k.uid === selectedSite)?.account
    );

    if (!onSearchUsers || onSearchUsers === "") return attendeesWithoutSite;

    const fuse = new Fuse(attendeesWithoutSite, { keys: ["name", "email"] });
    return fuse.search(onSearchUsers).map((k) => k.item);
  }, [onSearchUsers, attendees]);

  const hasValidAttendees = useMemo(() => {
    const attendeesWithoutSite = attendees.filter(
      (attendee) => attendee.email !== sites.find((k) => k.uid === selectedSite)?.account
    );
    return !!attendeesWithoutSite.length;
  }, [attendees]);

  const formatUser = (user: any, options: any = undefined): User => {
    return {
      ...options,
      name: user.displayName,
      email: user.emailAddress,
      flexAccess: {
        organizer: options?.flexAccess?.organizer || false,
        access: {
          authorized: true,
          sendQr: true,
          sendNotificationOnChecking: true,
          allowSelfAccess: true,
        },
        notifications: {
          sendCheckInNotification: true,
          email: false,
          sms: false,
          teams: false,
        },
        permissions: {},
      },
    };
  };

  const setupOrganizer = async (value: any, event: Template | undefined = undefined): Promise<User> => {
    let { params: defaultNotification, ...permissions } = await Api.getPermissions();

    defaultNotification = event
      ? {
          notify: event.organizer_params.notify,
          ...event.organizer_params,
        }
      : defaultNotification;
    setOwnerEmail(value.emailAddress);
    const organizer = formatUser(value, { owner: true, flexAccess: { organizer: true } });
    organizer.flexAccess.notifications = {
      sendCheckInNotification: defaultNotification.notify,
      email:
        [defaultNotification.media1, defaultNotification.media2, defaultNotification.media3].includes(1) &&
        permissions.allow_media_email,
      sms:
        [defaultNotification.media1, defaultNotification.media2, defaultNotification.media3].includes(2) &&
        permissions.allow_media_sms,
      teams:
        [defaultNotification.media1, defaultNotification.media2, defaultNotification.media3].includes(3) &&
        permissions.allow_media_teams,
    };
    organizer.flexAccess.permissions = permissions;

    return organizer;
  };
  const setupOrganizerFor = async (event: Template): Promise<User> => {
    let { params: defaultNotification, ...permissions } = await Api.getUsers(event.organized_for.uid);

    defaultNotification = event
      ? {
          notify: event.organized_for_params.notify,
          ...event.organized_for_params,
        }
      : defaultNotification;
    const organizer = formatUser(
      { displayName: event.organized_for.label, emailAddress: event.organized_for.label },
      { owner: false, flexAccess: { organizer: true } }
    );
    organizer.flexAccess.notifications = {
      sendCheckInNotification: defaultNotification.notify,
      email:
        [defaultNotification.media1, defaultNotification.media2, defaultNotification.media3].includes(1) &&
        permissions.allow_media_email,
      sms:
        [defaultNotification.media1, defaultNotification.media2, defaultNotification.media3].includes(2) &&
        permissions.allow_media_sms,
      teams:
        [defaultNotification.media1, defaultNotification.media2, defaultNotification.media3].includes(3) &&
        permissions.allow_media_teams,
    };
    organizer.flexAccess.permissions = permissions;

    return organizer;
  };

  const setupAttendee = (user: any, event: Template | undefined = undefined): User => {
    let attendee = formatUser(user);
    const eventAttendee = event && event.attendees.find((k) => k.identity === attendee.email);
    if (eventAttendee) {
      attendee.flexAccess.access = {
        authorized: eventAttendee.options.token || eventAttendee.options.access || eventAttendee.options.notify,
        sendQr: eventAttendee.options.token,
        allowSelfAccess: eventAttendee.options.access,
        sendNotificationOnChecking: eventAttendee.options.notify,
      };
    }
    return attendee;
  };

  const getOfficeUsers = async (
    { organizer, optionalAttendees, requiredAttendees }: AppointmentCompose,
    event?: Template | undefined
  ) => {
    organizer.getAsync(async ({ value }) => {
      const tmpOrganizers = [await setupOrganizer(value, event)];
      if (event && event.organized_for && event.organized_for.uid) {
        tmpOrganizers.push(await setupOrganizerFor(event));
      }
      setOrganizers((organizers) => [
        ...organizers,
        ...tmpOrganizers.filter((tmpO) => !organizers.find((o) => tmpO.email === o.email)),
      ]);
      optionalAttendees.getAsync(async ({ value }) => {
        const newUsers: any[] = [];
        for (const usr of value) {
          if (emailRegex().test(usr.emailAddress) && tmpOrganizers[0].email !== usr.emailAddress) {
            newUsers.push(setupAttendee(usr, event));
          }
        }
        requiredAttendees.getAsync(async ({ value }) => {
          for (const usr of value) {
            if (emailRegex().test(usr.emailAddress) && tmpOrganizers[0].email !== usr.emailAddress) {
              newUsers.push(setupAttendee(usr, event));
            }
          }
          setAttendees((oldAttendees) =>
            newUsers.map((u) => ({
              ...u,
              flexAccess: oldAttendees.find((oa) => oa.email === u.email)?.flexAccess ?? u.flexAccess, //if user already exists get flex access data
            }))
          );
        });
      });
    });
  };
  const handleEvent = async () => {
    const mailBoxItem: AppointmentCompose = window.Office.context.mailbox.item;
    if (mailBoxItem) {
      await getOfficeUsers(mailBoxItem);
    }
  };

  useEffect(() => {
    (async () => {
      await authUser();

      setCurrentUser(await Api.getPermissions());

      const tmpSites = await getSites();
      setSites(tmpSites);

      const mailBoxItem: AppointmentCompose = window.Office.context.mailbox.item;
      if (mailBoxItem) {
        mailBoxItem.getItemIdAsync(async ({ value }) => {
          if (value) {
            try {
              const event = await Api.getTemplate(value);
              if (event) {
                setRetrieveEvent(event);
                setIsAlreadyCreated(true);
              }

              await getOfficeUsers(mailBoxItem, event);
              await handleSiteChange(event.location.uid);
              setSelectedEntrance(event.address.uid);
              if (event.space?.uid) {
                setSelectedSpace(event.space.uid);
              }
            } catch (e) {
              await getOfficeUsers(mailBoxItem);
              if (tmpSites.length === 1) {
                await handleSiteChange(tmpSites[0].uid);
              }
            }
          } else {
            await getOfficeUsers(mailBoxItem);
            if (tmpSites.length === 1) {
              await handleSiteChange(tmpSites[0].uid);
            }
          }
          mailBoxItem.addHandlerAsync(window.Office.EventType.RecipientsChanged, handleEvent);
        });
      }
    })();
  }, []);

  const authUser = async () => {
    try {
      setLoading(true);
      const id_token: string = localStorage.getItem("flex_sso");
      const payload = jwtDecode(id_token);
      const validToken = await Api.authVerify();
      if (id_token && !validToken) {
        // @ts-ignore
        const data = await Api.connect({ identity: payload.preferred_username, credential: id_token });
        if (data) {
          localStorage.setItem("token", data.token);
        }
      }
    } catch (e) {
      console.error(e);
      setAuthError(true);
    } finally {
      setLoading(false);
    }
  };
  const getSites = async () => {
    let sites = [];
    try {
      setLoading(true);
      sites = await Api.getLocations();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
    return sites;
  };

  const handleSiteChange = async (siteUid: string) => {
    const oldSiteEmail = sites.find((k) => k.uid === selectedSite)?.account;
    setSelectedSite(siteUid);

    let tmp = sites.length ? sites : await getSites();
    const site = tmp.find((k) => k.uid === siteUid);

    const mailBoxItem = window.Office.context.mailbox.item;
    if (mailBoxItem) {
      mailBoxItem.organizer.getAsync(({ value }) => {
        const organizerEmail = value.emailAddress;

        mailBoxItem.requiredAttendees.getAsync(({ value }) => {
          const emails = value.map((usr) => usr.emailAddress).filter((email) => email !== oldSiteEmail);
          if (!emails.includes(site.account)) {
            mailBoxItem.requiredAttendees.setAsync(
              [...emails, site.account].filter((email) => email !== organizerEmail),
              () => {}
            );
          }
        });
      });
    }

    const entrances = site.addresses;
    setEntrances(entrances);
    if (entrances.length) {
      setSelectedEntrance(entrances[0].uid);
      updateOutlookLocation(entrances[0].fullname);
    }

    const spaces = await Api.getSpaces(siteUid);

    let allowDefaultSpace: boolean;
    if (currentUser) allowDefaultSpace = currentUser.allow_default_space;
    else {
      const user = await Api.getPermissions();
      setCurrentUser(user);
      allowDefaultSpace = user.allow_default_space;
    }

    setSpaces(spaces);
    setSelectedSpace(spaces.length ? "" : null);
    if (spaces.length === 1 && allowDefaultSpace) {
      setSelectedSpace(spaces[0].uid);
    }
  };
  const handleEntranceChange = (uid: string) => {
    setSelectedEntrance(uid);
    const entrance = entrances.find((k) => k.uid === uid);
    updateOutlookLocation(entrance.fullname);
  };
  const updateOutlookLocation = (addr) => {
    if (!isAlreadyCreated) {
      const mailBoxItem: AppointmentCompose = window.Office.context.mailbox.item;
      if (addr) {
        mailBoxItem.location.setAsync(addr);
      }
    }
  };

  const onSelectAttendee = (attendee: any) => {
    setSelectedAttendee(attendee);
    setAttendeeModalState(true);
  };
  const onEditAttendee = (event: React.ChangeEvent<HTMLInputElement>) => {
    const editedAccess =
      event.target.name === "sendQr" && !event.target.checked
        ? { sendQr: false, sendNotificationOnChecking: false, allowSelfAccess: false, authorized: false }
        : { [event.target.name]: event.target.checked, authorized: true };
    const editedAttendee = {
      ...selectedAttendee,
      flexAccess: {
        ...selectedAttendee.flexAccess,
        access: {
          ...selectedAttendee.flexAccess.access,
          ...editedAccess,
        },
      },
    };
    setSelectedAttendee(editedAttendee);
    setAttendees((as) => as.map((a) => (a.email === editedAttendee.email ? editedAttendee : a)));
  };

  const handleNewOrganizer = async (value) => {
    if (!organizers.find((k) => k.email === value.email) && organizers.length <= 1) {
      const organizer = formatUser(
        { ...value, displayName: value.label, emailAddress: value.email },
        { uid: value.uid, owner: false, flexAccess: { organizer: true } }
      );
      const { params: defaultNotification, ...permissions } = await Api.getUsers(value.uid);
      organizer.flexAccess.notifications = {
        sendCheckInNotification: defaultNotification.notify,
        email:
          [defaultNotification.media1, defaultNotification.media2, defaultNotification.media3].includes(1) &&
          permissions.allow_media_email,
        sms:
          [defaultNotification.media1, defaultNotification.media2, defaultNotification.media3].includes(2) &&
          permissions.allow_media_sms,
        teams:
          [defaultNotification.media1, defaultNotification.media2, defaultNotification.media3].includes(3) &&
          permissions.allow_media_teams,
      };
      organizer.flexAccess.permissions = permissions;

      setOrganizers([...organizers, organizer]);
    }
  };
  const onSelectOrganizer = (organizer: any) => {
    setSelectedOrganizer(organizer);
    setOrganizerModalState(true);
  };
  const onSwitchedAccessAuthorized = (e: React.ChangeEvent<HTMLInputElement>, attendee: any) => {
    setAttendees((attds) => {
      return attds.map((a) => {
        if (attendee.email === a.email) {
          a.flexAccess.access.authorized = e.target.checked;
          if (e.target.checked) {
            a.flexAccess.access.sendQr = true;
          }
        }
        return a;
      });
    });
  };
  const onRemoveOrganizer = (organizerIndex: any) => {
    setOrganizers((organizers) => organizers.filter((_, k) => k !== organizerIndex));
  };
  const onEditOrganizer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const editedNotifications =
      event.target.name === "sendCheckInNotification" && !event.target.checked
        ? { sendCheckInNotification: false, email: false, teams: false, sms: false }
        : { [event.target.name]: event.target.checked };
    const editedOrganizer = {
      ...selectedOrganizer,
      flexAccess: {
        ...selectedOrganizer.flexAccess,
        notifications: { ...selectedOrganizer.flexAccess.notifications, ...editedNotifications },
      },
    };
    setSelectedOrganizer(editedOrganizer);
    setOrganizers((os) => os.map((o) => (o.email === editedOrganizer.email ? editedOrganizer : o)));
  };

  const formatTemplate = (msgId: string) => {
    const owner = organizers[0];
    const organizedForParams =
      organizers.length > 1 && owner.flexAccess.permissions.allow_delegate
        ? {
            organized_for: {
              uid: organizers[1].uid,
            },
            organized_for_params: {
              notify: organizers[1].flexAccess.notifications.sendCheckInNotification,
              media1:
                organizers[1].flexAccess.notifications.sendCheckInNotification &&
                organizers[1].flexAccess.notifications.email
                  ? 1
                  : 0,
              media2:
                organizers[1].flexAccess.notifications.sendCheckInNotification &&
                organizers[1].flexAccess.notifications.sms
                  ? 2
                  : 0,
              media3:
                organizers[1].flexAccess.notifications.sendCheckInNotification &&
                organizers[1].flexAccess.notifications.teams
                  ? 3
                  : 0,
            },
          }
        : undefined;
    return {
      msgid: msgId,
      attendees: attendees
        .filter((attendee) => attendee.email !== sites.find((s) => s.uid === selectedSite)?.account)
        .map((a) => {
          return {
            identity: a.email,
            options: {
              token: a.flexAccess.access.authorized && a.flexAccess.access.sendQr,
              access: a.flexAccess.access.authorized && a.flexAccess.access.allowSelfAccess,
              notify: a.flexAccess.access.authorized && a.flexAccess.access.sendNotificationOnChecking,
            },
          };
        }),
      address: {
        uid: selectedEntrance,
      },
      location: {
        uid: selectedSite,
      },
      space: selectedSpace
        ? {
            uid: selectedSpace || undefined,
          }
        : undefined,
      organizer_params: {
        notify: owner.flexAccess.notifications.sendCheckInNotification,
        media1: owner.flexAccess.notifications.sendCheckInNotification && owner.flexAccess.notifications.email ? 1 : 0,
        media2: owner.flexAccess.notifications.sendCheckInNotification && owner.flexAccess.notifications.sms ? 2 : 0,
        media3: owner.flexAccess.notifications.sendCheckInNotification && owner.flexAccess.notifications.teams ? 3 : 0,
      },
      ...organizedForParams,
    };
  };

  const handleConfirm = () => {
    setConfirmationModalState(false);
    const mailBoxItem: Office.AppointmentCompose = window.Office.context.mailbox.item;

    if (isAlreadyCreated && retrieveEvent) {
      mailBoxItem.saveAsync(async (response) => {
        try {
          setLoadingForm(true);
          const form = formatTemplate(response.value) as Template;
          form.msgid = response.value;
          form.settingsid = retrieveEvent.settingsid;
          if (isAlreadyCreated) await Api.updateTemplate(form);
          else await Api.createTemplate(form);
          setSuccessModalState(true);
        } catch (e) {
          console.error(e);
        } finally {
          setLoadingForm(false);
        }
      });
    } else {
      const generateRandomString = (length, randomString = "") => {
        randomString += Math.random().toString(20).substr(2, length);
        if (randomString.length > length) return randomString.slice(0, length);
        return generateRandomString(length, randomString);
      };
      const tokenTmp = generateRandomString(128);
      const flexIdBody = `<p style="color: black; font-size: 4px">flexid:${tokenTmp}</p>`;
      mailBoxItem.body.getAsync("html", async (bodyResponse) => {
        mailBoxItem.body.setAsync(
          bodyResponse.value.replace("</body>", `${flexIdBody}</body>`),
          { coercionType: "html" },
          async (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              mailBoxItem.saveAsync(async (response) => {
                setLoadingForm(true);
                const form = formatTemplate(response.value) as Template;
                form.msgid = response.value;
                form.settingsid = tokenTmp;
                await Api.createTemplate(form);
                setSuccessModalState(true);
                setLoadingForm(false);
              });
            } else {
              console.error(asyncResult.error);
            }
          }
        );
      });
    }
  };

  if (loading)
    return (
      <Container
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="primary" />
      </Container>
    );

  if (authError)
    return (
      <Container
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert severity="error">
          <AlertTitle>
            <strong>Erreur</strong>
          </AlertTitle>
          Une erreur s'est produite ou vous n'avez les permissions pour utiliser cette application
        </Alert>
      </Container>
    );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "start",
          padding: "1rem",
        }}
      >
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingForm}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Card style={{ display: "flex", alignItems: "center", backgroundColor: "white" }}>
          <CardContent style={{ width: "100%" }}>
            <Typography color="secondary" style={{ fontSize: 15, fontWeight: "600" }}>
              Envoyer une invitation digitale à
            </Typography>
            <TextField
              label={
                <Typography color="secondary" style={{ fontSize: 13 }}>
                  Recherche...
                </Typography>
              }
              variant="outlined"
              size="small"
              value={onSearchUsers}
              style={{ width: "100%", margin: "1rem 0 .5rem 0", fontSize: 13 }}
              color="secondary"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOnSearchUsers(event.target.value);
              }}
            />
            <FormGroup>
              {filteredAttendees.map((attendee: any, index: number) => (
                <Grid key={index} display="flex" flexDirection="row" justifyContent="space-between">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={attendee.flexAccess.access.authorized}
                        onChange={(e) => onSwitchedAccessAuthorized(e, attendee)}
                      />
                    }
                    label={
                      <Typography color="secondary" style={{ fontSize: 13 }}>
                        {attendee.email}
                      </Typography>
                    }
                    style={{ padding: ".25rem .5rem" }}
                  />
                  {organizers[0].flexAccess.permissions.allow_customize && (
                    <IconButton
                      disabled={!attendee.flexAccess.access.authorized}
                      onClick={() => onSelectAttendee(attendee)}
                    >
                      <EditIcon color={attendee.flexAccess.access.authorized ? "secondary" : "disabled"} />
                    </IconButton>
                  )}
                </Grid>
              ))}
            </FormGroup>
          </CardContent>
        </Card>

        <Card style={{ backgroundColor: "white", marginTop: 15 }}>
          <CardContent style={{ width: "100%" }}>
            <Typography color="secondary" style={{ fontSize: 15, fontWeight: "600" }}>
              Site
            </Typography>
            <CustomSelect
              label="Sélectionner un site"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSiteChange(event.target.value)}
              value={selectedSite}
              icon={<HomeIcon color="secondary" />}
              data={sites}
              itemLabel="label"
              itemValue="uid"
              forceSelected
              disabled={isAlreadyCreated}
            />
            <CustomSelect
              label="Sélectionner une entrée"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleEntranceChange(event.target.value)}
              value={selectedEntrance}
              icon={<MeetingRoomIcon color="secondary" />}
              data={entrances}
              itemLabel="name"
              itemValue="uid"
              forceSelected
              disabled={isAlreadyCreated}
            />
            {spaces?.length !== 0 && (
              <CustomSelect
                label="Sélectionner un espace"
                onChange={(event) => setSelectedSpace(event.target.value)}
                value={selectedSpace}
                icon={<LockIcon color="secondary" />}
                data={spaces}
                itemLabel="label"
                itemValue="uid"
                allowNull
                disabled={isAlreadyCreated || !selectedSite}
              />
            )}
          </CardContent>
        </Card>

        <Card style={{ display: "flex", alignItems: "center", backgroundColor: "white", marginTop: 15 }}>
          <CardContent style={{ width: "100%" }}>
            <Typography color="secondary" style={{ fontSize: 15, fontWeight: "600" }}>
              Organisateur(s)
            </Typography>
            {organizers.length && organizers[0].flexAccess.permissions.allow_delegate && (
              <SelectUserInput onChange={handleNewOrganizer} disabled={organizers.length >= 2} />
            )}
            <FormGroup>
              {organizers.map((organizer: any, index: number) => (
                <Grid key={index} display="flex" flexDirection="row" justifyContent="space-between">
                  <FormControlLabel
                    control={
                      <IconButton
                        disabled={organizer.email === ownerEmail || isAlreadyCreated}
                        style={{ opacity: organizer.email === ownerEmail ? 0 : 100 }}
                        onClick={() => onRemoveOrganizer(index)}
                      >
                        <DeleteIcon color={organizer.email === ownerEmail ? "disabled" : "error"} />
                      </IconButton>
                    }
                    label={
                      <Typography color="secondary" style={{ fontSize: 13 }}>
                        {organizer.email.length > 20 ? organizer.email.substring(0, 20) + "..." : organizer.email}
                      </Typography>
                    }
                    style={{ padding: ".25rem .5rem" }}
                  />
                  <IconButton disabled={!organizer.flexAccess.organizer} onClick={() => onSelectOrganizer(organizer)}>
                    <EditNotificationsIcon color={organizer.flexAccess.organizer ? "secondary" : "disabled"} />
                  </IconButton>
                </Grid>
              ))}
            </FormGroup>
          </CardContent>
        </Card>

        <Card style={{ backgroundColor: "white", marginTop: 15, position: "sticky", bottom: 0, zIndex: 1000 }}>
          <CardContent style={{ width: "100%" }}>
            <Button
              style={{
                backgroundColor:
                  !selectedSite || !selectedEntrance || !hasValidAttendees
                    ? theme.palette.grey[500]
                    : theme.palette.primary.main,
                color: "white",
                width: "100%",
              }}
              onClick={() => setConfirmationModalState(true)}
              disabled={!selectedSite || !selectedEntrance || !hasValidAttendees}
            >
              Valider
            </Button>
          </CardContent>
        </Card>

        <Typography color="secondary" style={{ fontSize: 10, marginTop: 4, textAlign: "right" }}>
          Version {process.env.VERSION ?? "1.0.9"}
        </Typography>

        {confirmationModalState && (
          <ConfirmationModal
            open={confirmationModalState}
            onClose={() => setConfirmationModalState(false)}
            onConfirm={handleConfirm}
          />
        )}

        {attendeeModalState && selectedAttendee && (
          <AttendeeModal
            open={attendeeModalState}
            onClose={() => setAttendeeModalState(false)}
            attendee={selectedAttendee}
            onEditAttendee={onEditAttendee}
          />
        )}

        {organizerModalState && selectedOrganizer && (
          <OrganizerModal
            open={organizerModalState}
            onClose={() => setOrganizerModalState(false)}
            organizer={selectedOrganizer}
            onEditOrganizer={onEditOrganizer}
          />
        )}

        {successModalState && <SuccessModal open={successModalState} isAlreadyCreated={isAlreadyCreated} />}
      </Container>
    </ThemeProvider>
  );
};
