import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    primary: {
      main: "#3478F6",
    },
    secondary: {
      main: "#38454d",
    },
    background: {
      default: "#F5F5F5",
    },
  },
});
