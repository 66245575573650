import React from "react";
import {
  Modal,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CardContent,
  Card,
  IconButton,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type OrganizerModalProps = {
  open: boolean;
  onClose: () => void;
  organizer: any;
  onEditOrganizer: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const OrganizerModal = ({ open, onClose, organizer, onEditOrganizer }: OrganizerModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Card
        style={{
          backgroundColor: "white",
          maxHeight: "60%",
          width: "90%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          outline: "none",
        }}
      >
        <IconButton
          onClick={onClose}
          style={{
            position: "absolute",
            right: ".5rem",
            top: ".5rem",
          }}
        >
          <CloseIcon />
        </IconButton>
        <CardContent style={{ padding: "2rem 1rem 1rem 1rem" }}>
          <FormControlLabel
            control={
              <Switch
                checked={organizer.flexAccess.notifications.sendCheckInNotification}
                onChange={onEditOrganizer}
                name="sendCheckInNotification"
              />
            }
            label={
              <Typography color="secondary" style={{ fontSize: 15, fontWeight: "600" }}>
                Envoyer une notification d'arrivée à {organizer.email}:
              </Typography>
            }
            style={{ padding: ".5rem" }}
          />
          <FormGroup style={{ padding: ".5rem 1.5rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={organizer.flexAccess.notifications.email}
                  onChange={onEditOrganizer}
                  name="email"
                  disabled={
                    !organizer.flexAccess.notifications.sendCheckInNotification ||
                    !organizer.flexAccess.permissions.allow_media_email
                  }
                />
              }
              label={
                <Typography color="secondary" style={{ fontSize: 13 }}>
                  Email
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={organizer.flexAccess.notifications.teams}
                  onChange={onEditOrganizer}
                  name="teams"
                  disabled={
                    !organizer.flexAccess.notifications.sendCheckInNotification ||
                    !organizer.flexAccess.permissions.allow_media_teams
                  }
                />
              }
              label={
                <Typography color="secondary" style={{ fontSize: 13 }}>
                  Teams
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={organizer.flexAccess.notifications.sms}
                  onChange={onEditOrganizer}
                  name="sms"
                  disabled={
                    !organizer.flexAccess.notifications.sendCheckInNotification ||
                    !organizer.flexAccess.permissions.allow_media_sms
                  }
                />
              }
              label={
                <Typography color="secondary" style={{ fontSize: 13 }}>
                  SMS
                </Typography>
              }
            />
          </FormGroup>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OrganizerModal;
