import React from "react";
import { Modal, CardContent, Card, Typography, Alert } from "@mui/material";

type SuccessModalProps = {
  open: boolean;
  isAlreadyCreated: boolean;
};

const SuccessModal = ({ open, isAlreadyCreated }: SuccessModalProps) => {
  return (
    <Modal open={open}>
      <Card
        style={{
          backgroundColor: "white",
          maxHeight: "60%",
          width: "90%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          outline: "none",
        }}
      >
        <CardContent style={{ padding: "2rem 1rem 1rem 1rem" }}>
          {!isAlreadyCreated && (
            <Typography color="secondary" style={{ marginBottom: ".5rem" }}>
              <strong>Vos préférences FlexAccess ont été enregistrées !</strong>
            </Typography>
          )}
          {isAlreadyCreated && (
            <Typography color="secondary" style={{ marginBottom: ".5rem" }}>
              <strong>Vos préférences FlexAccess ont été mises à jour !</strong>
            </Typography>
          )}

          <Alert severity="info" style={{ marginBottom: "1rem" }}>
            Pour envoyer l’email d’invitation aux participants, veuillez cliquer sur Envoyer
          </Alert>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default SuccessModal;
