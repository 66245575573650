import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./components/App";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as process from "process";

const env = process.env.ENV ?? "production";

Sentry.init({
  dsn:
    process.env.SENTRY_DSN ??
    "https://8d12be8f1c0b4c3cbab119666fd81605@o4504491057414144.ingest.sentry.io/4504491061608448",
  enabled: env !== "development",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
});

/* global Office */

Office.onReady(() => {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(<App />);
});
