import React from "react";
import { Modal, CardContent, Card, Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "./theme";
import CheckIcon from "@mui/icons-material/Check";

type ConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmationModal = ({ open, onClose, onConfirm }: ConfirmationModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Card
        style={{
          backgroundColor: "white",
          maxHeight: "60%",
          width: "90%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          outline: "none",
        }}
      >
        <CardContent style={{ padding: "2rem 1rem 1rem 1rem" }}>
          <Typography color="secondary" style={{ marginBottom: ".5rem" }}>
            <strong>Vos préférences FlexAccess vont être enregistrées. Voulez-vous continuer ?</strong>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                color="error"
                style={{
                  width: "100%",
                }}
                variant="outlined"
                onClick={onClose}
              >
                <CloseIcon />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  width: "100%",
                }}
                onClick={onConfirm}
              >
                <CheckIcon />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ConfirmationModal;
